import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../services/auth.service';
import { UnsubscribeBase } from '../../unsubscribe.base';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent extends UnsubscribeBase implements OnInit {
  public registerForm: FormGroup;

  @Input() noPassword: boolean = false;
  public isLoading: boolean = false;
  public showError: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private toastrService: ToastrService,
  ) {
    super();
  }
  ngOnInit(): void {
    this.initForm();
  }

  private initForm() {
    const passwordValidators: ValidatorFn[] = [];

    if (!this.noPassword) {
      passwordValidators.push(Validators.required);
    }
    this.registerForm = new FormGroup({
      gender: new FormControl(undefined, [Validators.required]),
      firstName: new FormControl(undefined, [Validators.required]),
      infix: new FormControl(undefined, []),
      lastName: new FormControl(undefined, [Validators.required]),
      username: new FormControl(undefined, [
        Validators.required,
        Validators.email,
      ]),
      password: new FormControl(undefined, passwordValidators),
    });
  }

  public onSubmit() {
    if (this.registerForm.invalid) {
      return;
    }

    const {
      username,
      password,
      firstName: firstNameValue,
      infix,
      gender,
      lastName: lastNameValue,
    }: {
      username: string;
      password: string;
      firstName: string;
      gender: 'Male' | 'Female' | 'unknown';
      infix: string;
      lastName: string;
    } = this.registerForm.value;

    this.isLoading = true;
    this.showError = false;

    const firstName = this.capitalizeFirstLetter(firstNameValue);
    const lastName = this.capitalizeFirstLetter(lastNameValue);
    const sub$ = this.authService
      .register({ username, password, firstName, lastName, infix, gender })
      .subscribe(
        () => {
          this.isLoading = false;
          this.initForm();
          this.toastrService.success(
            'Bedankt voor het registeren! Kijk in uw mail voor de activatie link',
          );
          this.router.navigate(['/login']);
        },
        (error) => {
          this.isLoading = false;
          if (error.status === 409) {
            this.showError = true;
            this.toastrService.error('Dit emailadres bestaat al');
          } else {
            // Status code is 500, meaning something went wrong in the API
            this.toastrService.error(
              'Iets ging fout bij het registreren. Probeer het opnieuw',
            );
          }
        },
      );

    this.subscriptions.push(sub$);
  }

  private capitalizeFirstLetter(value: string): string {
    value = value.charAt(0).toUpperCase() + value.slice(1);
    return value;
  }
}
