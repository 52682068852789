import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Action } from '../../interfaces/action.interface';
import { Campaign } from '../../interfaces/campaign.interface';
import { ActionService } from '../../services/action.service';
import { AppService } from '../../services/app.service';
import { CampaignService } from '../../services/campaign.service';
import { UnsubscribeBase } from '../../unsubscribe.base';

@Component({
  selector: 'app-action-detail-page',
  templateUrl: './action-detail-page.component.html',
  styleUrls: ['./action-detail-page.component.scss'],
})
export class ActionDetailPageComponent
  extends UnsubscribeBase
  implements OnInit
{
  public action: Action;
  public donationCount = 0;
  public collectedAmountPercentage: number;
  public createdText: string;
  public remainingAmount: number;
  public remainingDays: number;
  public routeUrl: string;
  public campaign: Campaign;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private actionService: ActionService,
    private campaignService: CampaignService,
    private appService: AppService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    super();
  }

  ngOnInit(): void {
    const action$ = this.activatedRoute.params
      .pipe(
        switchMap((params) => this.actionService.getAction(params.slug)),
        switchMap((action) => {
          if (isPlatformBrowser(this.platformId)) {
            this.routeUrl = this.document.location.origin + this.router.url;
          } else {
            this.routeUrl = '';
          }
          this.action = action;
          this.donationCount = action.donations.filter(
            (item) => item.status === 'collected',
          ).length;
          this.appService.setTitle(`${this.action.title} | ActionPlatform`);
          this.appService.setDescription(this.action.description);
          this.appService.setImage(this.action.banner);
          this.appService.setUrl(this.router.url);

          this.collectedAmountPercentage =
            (this.action.collectedAmount / this.action.targetAmount) * 100;
          this.remainingAmount = Math.round(
            Math.max(0, this.action.targetAmount - this.action.collectedAmount),
          );

          const currentDate = new Date();
          const endDate = new Date(this.action.endDate);

          this.remainingDays = Math.max(
            0,
            Math.round(
              (endDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24),
            ),
          );

          return this.campaignService.getCampaign(this.action.campaign._id);
        }),
      )
      .subscribe(
        (campaign) => {
          this.campaign = campaign;
          if (this.campaign.targets.length > 1) {
            const targetIndex = this.campaign.targets.findIndex(
              (item) => item.value === this.action.target,
            );

            if (targetIndex >= 0) {
              this.createdText = `Deze actie is aangemaakt door ${this.action.creatorName}, voor ${this.campaign.targets[targetIndex].label}`;
            }
          }

          if (!this.createdText) {
            this.createdText = `Deze actie is aangemaakt door: ${this.action.creatorName}`;
          }
        },
        (error) => {
          if (error.status === 404) {
            this.router.navigate(['404']);
          }
        },
      );

    this.subscriptions.push(action$);
  }
}
