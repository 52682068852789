<div class="container page">
  <div class="columns is-centered">
    <div class="column is-10 is-10-mobile is-offset-1-mobile">
      <h1 class="title is-1 has-text-centered">Mijn Acties</h1>
      <h2 class="title is-3 has-text-centered">
        Mijn Acties die al gestart zijn
      </h2>
      <div class="is-flex is-justify-content-center buttons">
        <a
          routerLink="/create"
          routerLinkActive="active"
          class="button is-link"
        >
          <span class="icon-text">
            <span class="icon">
              <i class="far fa-plus-square"></i>
            </span>
            <span>Zelf een actie aanmaken</span>
          </span>
        </a>
      </div>
      <div *ngIf="myActiveActions.length > 0" class="columns is-multiline">
        <div
          *ngFor="let action of myActiveActions; trackBy: trackActions"
          class="column is-one-third-desktop is-half-tablet is-full-mobile"
        >
          <app-action-list-item
            [action]="action"
            [myAction]="true"
            (pauseMyActionEmit)="pauseMyAction($event)"
          ></app-action-list-item>
        </div>
      </div>

      <h3
        class="has-text-centered section"
        *ngIf="myActiveActions.length === 0"
      >
        Er zijn geen acties gevonden
      </h3>

      <div class="is-flex is-justify-content-center buttons">
        <a
          routerLink="/create"
          routerLinkActive="active"
          class="button is-link"
        >
          <span class="icon-text">
            <span class="icon">
              <i class="far fa-plus-square"></i>
            </span>
            <span>Zelf een actie aanmaken</span>
          </span>
        </a>
      </div>
    </div>
  </div>
</div>
