import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Editor, Toolbar } from 'ngx-editor';
import { Action, ActionInput } from '../../interfaces/action.interface';
import { Campaign } from '../../interfaces/campaign.interface';
import { CampaignService } from '../../services/campaign.service';
import { UnsubscribeBase } from '../../unsubscribe.base';

@Component({
  selector: 'app-action-form',
  templateUrl: './action-form.component.html',
  styleUrls: ['./action-form.component.scss'],
})
export class ActionFormComponent extends UnsubscribeBase implements OnInit {
  @Input() formMode: 'create' | 'edit';
  @Input() action: Action; // only if formMode is edit
  @Input() isLoading: boolean = false; // Used for enabling or disabling submit

  @Output() actionEmitter = new EventEmitter<ActionInput>();

  actionForm: FormGroup;
  campaigns: Campaign[];
  today: Date;
  datePipe = new DatePipe('en-Us');
  bannerUrl: string;
  selectedCampaign: Campaign;
  suggestedAmountKeys: Array<string> = [];
  activeStep: 1 | 2 = 1;
  editor: Editor = new Editor();
  toolbar: Toolbar = [
    ['link', { heading: ['h2', 'h3'] }],
    ['bold', 'italic', 'underline'],
  ];

  hideCampaignChanger = false;
  hideTargetChanger = false;

  compareCampaigns(a: Campaign, b: Campaign) {
    if (!a || !b) {
      return false;
    }
    return a._id === b._id;
  }

  constructor(private campaignService: CampaignService) {
    super();
  }

  ngOnInit(): void {
    this.today = new Date();

    const sub$ = this.campaignService
      .getCampaigns({ perPage: 9999, filtered: false })
      .subscribe((result) => {
        this.campaigns = result.data;
        this.setupComponent();
      });

    this.subscriptions.push(sub$);
  }

  private setupComponent() {
    if (this.formMode === 'edit') {
      const campaignId =
        typeof this.action.campaign === 'string'
          ? this.action.campaign
          : this.action.campaign._id;

      this.selectedCampaign = this.campaigns.filter(
        (cam) => cam._id === campaignId,
      )[0];

      this.switchStep(2);
    }

    if (this.campaigns.length === 1) {
      this.hideCampaignChanger = true;
      this.selectedCampaign = this.campaigns[0];
      this.switchStep(2);
    }
  }

  ngOnDestroy(): void {
    this.editor.destroy();
    super.ngOnDestroy();
  }

  getFileUrl(result: string) {
    this.bannerUrl = result;
    if (this.actionForm) {
      this.actionForm.get('banner')?.setValue(this.bannerUrl);
    }
  }

  switchStep(step: 1 | 2 = 1) {
    if (step === 2 && !this.selectedCampaign) {
      // Maybe show a message
      return;
    }

    if (step === 2) {
      this.initForm();
    }

    this.activeStep = step;
  }

  private initForm() {
    if (this.formMode === 'edit') {
      return this.initEditForm();
    }

    this.initCreateForm();
  }

  private initCreateForm() {
    this.suggestedAmountKeys = [];

    const suggestedAmounts: {
      [key: string]: AbstractControl;
    } = {};

    const endDate = this.selectedCampaign.endDate
      ? new Date(this.selectedCampaign.endDate)
      : new Date(this.today.getTime() + 1000 * 60 * 60 * 24 * 31);

    if (this.selectedCampaign.suggestedAmounts.length <= 0) {
      suggestedAmounts.sga0 = new FormControl(
        Math.max(5, this.selectedCampaign.minimumAmount),
        Validators.min(this.selectedCampaign.minimumAmount),
      );
      suggestedAmounts.sga1 = new FormControl(
        Math.max(10, this.selectedCampaign.minimumAmount),
        Validators.min(this.selectedCampaign.minimumAmount),
      );
      suggestedAmounts.sga2 = new FormControl(
        Math.max(15, this.selectedCampaign.minimumAmount),
        Validators.min(this.selectedCampaign.minimumAmount),
      );
    } else {
      this.selectedCampaign.suggestedAmounts.forEach((item, index) => {
        suggestedAmounts[`sga${index}`] = new FormControl(
          item,
          Validators.min(this.selectedCampaign.minimumAmount),
        );
      });
    }

    this.hideTargetChanger = this.selectedCampaign.targets.length === 1;

    let targetVal = '';
    if (this.hideTargetChanger) {
      targetVal = this.selectedCampaign.targets[0].value;
    }

    this.actionForm = new FormGroup({
      title: new FormControl(undefined, [
        Validators.required,
        Validators.maxLength(50),
      ]),
      target: new FormControl({ value: targetVal, disabled: !!targetVal }, [
        Validators.required,
      ]),
      group: new FormControl(
        {
          value: '',
          disabled: this.selectedCampaign.groups.length < 1,
        },
        [],
      ),
      banner: new FormControl(this.bannerUrl, [Validators.required]),
      description: new FormControl(undefined),
      content: new FormControl(''),
      targetAmount: new FormControl(undefined),
      campaign: new FormControl(
        { value: this.selectedCampaign._id, disabled: true },
        [Validators.required],
      ),
      startDate: new FormControl(
        this.datePipe.transform(this.today, 'YYYY-MM-dd'),
      ),
      endDate: new FormControl(
        this.datePipe.transform(new Date(endDate), 'YYYY-MM-dd'),
      ),
      creatorName: new FormControl(undefined, [Validators.required]),
      suggestedAmounts: new FormGroup(suggestedAmounts),
    });

    this.suggestedAmountKeys = Object.keys(
      (this.actionForm.controls.suggestedAmounts as FormGroup).controls,
    );
  }

  private initEditForm() {
    this.suggestedAmountKeys = [];

    const endDate = this.action.endDate
      ? new Date(this.action.endDate)
      : new Date(this.today.getTime() + 1000 * 60 * 60 * 24 * 31);

    const suggestedAmounts: {
      [key: string]: AbstractControl;
    } = {};

    if (this.selectedCampaign.suggestedAmounts.length <= 0) {
      suggestedAmounts.sga0 = new FormControl(
        Math.max(
          this.action.suggestedAmounts[0] || 5,
          this.selectedCampaign.minimumAmount,
        ),
        Validators.min(this.selectedCampaign.minimumAmount),
      );
      suggestedAmounts.sga1 = new FormControl(
        Math.max(
          this.action.suggestedAmounts[1] || 10,
          this.selectedCampaign.minimumAmount,
        ),
        Validators.min(this.selectedCampaign.minimumAmount),
      );
      suggestedAmounts.sga2 = new FormControl(
        Math.max(
          this.action.suggestedAmounts[2] || 15,
          this.selectedCampaign.minimumAmount,
        ),
        Validators.min(this.selectedCampaign.minimumAmount),
      );
    } else {
      this.selectedCampaign.suggestedAmounts.forEach((item, index) => {
        suggestedAmounts[`sga${index}`] = new FormControl(
          this.action.suggestedAmounts[index] || item,
          Validators.min(this.selectedCampaign.minimumAmount),
        );
      });
    }

    this.bannerUrl = this.action.banner;

    this.actionForm = new FormGroup({
      title: new FormControl(this.action.title, [Validators.required]),
      target: new FormControl(this.action.target, [Validators.required]),
      group: new FormControl(
        {
          value: this.action.group,
          disabled: this.selectedCampaign.groups.length < 1,
        },
        [],
      ),
      banner: new FormControl(this.bannerUrl, [Validators.required]),
      description: new FormControl(this.action.description),
      content: new FormControl(this.action.content),
      targetAmount: new FormControl(this.action.targetAmount),
      campaign: new FormControl(
        { value: this.selectedCampaign._id, disabled: true },
        [Validators.required],
      ),
      startDate: new FormControl(
        this.datePipe.transform(new Date(this.action.startDate), 'YYYY-MM-dd'),
      ),
      endDate: new FormControl(
        this.datePipe.transform(new Date(endDate), 'YYYY-MM-dd'),
      ),
      creatorName: new FormControl(this.action.creatorName, [
        Validators.required,
      ]),
      suggestedAmounts: new FormGroup(suggestedAmounts),
    });

    this.suggestedAmountKeys = Object.keys(
      (this.actionForm.controls.suggestedAmounts as FormGroup).controls,
    );
  }

  getSuggestedAmountValid(id: string): boolean {
    const item = (this.actionForm.controls.suggestedAmounts as FormGroup).get(
      id,
    );

    return !!item && ((item.touched && item.valid) || item?.untouched);
  }

  onSubmit() {
    if (this.actionForm.invalid) {
      return;
    }

    const {
      campaign,
      title,
      description,
      content,
      creatorName,
      published,
      targetAmount,
      startDate,
      endDate,
      suggestedAmounts,
      banner,
      target,
      group,
    }: any = this.actionForm.getRawValue();

    const newSuggestedAmounts: number[] = [];

    if (suggestedAmounts) {
      Object.values<number>(suggestedAmounts).forEach((sga) => {
        if (!Number.isNaN(sga)) {
          newSuggestedAmounts.push(+sga);
        }
      });
    }

    this.actionEmitter.emit({
      campaign,
      title,
      banner,
      description,
      content,
      creatorName,
      published,
      targetAmount,
      startDate,
      endDate,
      suggestedAmounts: newSuggestedAmounts,
      target,
      group,
    });
  }
}
