<h2 class="title is-3 pt-2">Inloggen op 'Mijn Acties'</h2>
<form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
  <div class="field">
    <div class="control">
      <label class="label" for="username">
        Wat is het e-mailadres waarmee je je al eerder hebt aangemeld?
      </label>
      <input
        id="usernameLogin"
        name="username"
        type="email"
        placeholder="info@maf.nl"
        formControlName="username"
        required
        class="input"
      />
    </div>
  </div>
  <div class="field" *ngIf="!noPassword">
    <div class="control">
      <label class="label" for="password">Password:</label>
      <input
        id="passwordLogin"
        name="password"
        type="password"
        placeholder="password"
        formControlName="password"
        required
        class="input"
      />
    </div>
    <p class="help is-danger is-size-6 has-text-weight-bold" *ngIf="showError">
      Gebruikersnaam en wachtwoord komen niet overeen
    </p>
  </div>
  <div class="field pt-2">
    <div
      class="control is-flex is-justify-content-space-between is-align-content-center is-align-items-center"
    >
      <button
        class="button is-primary"
        type="submit"
        [disabled]="loginForm.invalid || isLoading"
      >
        Log in
      </button>
      <span>
        Nog geen account? Klik dan
        <a class="" [routerLink]="['/register']" routerLinkActive="link-active">
          hier</a
        >.
      </span>
    </div>
  </div>
</form>
