import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppService } from 'src/app/services/app.service';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';
import { CacheService } from '../../services/cache.service';
import { UnsubscribeBase } from '../../unsubscribe.base';

@Component({
  selector: 'app-login-register-page',
  templateUrl: './login-register-page.component.html',
  styleUrls: ['./login-register-page.component.scss'],
})
export class LoginRegisterPageComponent
  extends UnsubscribeBase
  implements OnInit
{
  public useMagicLink: boolean = environment.magicLinkLogin;

  constructor(
    private router: Router,
    private authService: AuthService,
    private cacheService: CacheService,
    private appService: AppService,
  ) {
    super();
  }

  ngOnInit(): void {
    const sub$ = combineLatest([
      this.authService.authStatus$.pipe(take(1)),
      this.appService.platformSettings$,
    ]).subscribe(([isLoggedIn, settings]) => {
      this.useMagicLink = settings && settings.useUserPassword === false;
      if (!isLoggedIn) {
        return;
      }

      const sessionURL = this.cacheService.get('redirect-url', 'session');
      if (sessionURL) {
        this.cacheService.remove('redirect-url', 'session');
        return this.router.navigate([sessionURL]);
      }

      return this.router.navigate(['/']);
    });

    this.subscriptions.push(sub$);
  }
}
