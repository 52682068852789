import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Action } from '../../interfaces/action.interface';
import { ActionService } from '../../services/action.service';
import { AppService } from '../../services/app.service';
import { AuthService } from '../../services/auth.service';
import { UnsubscribeBase } from '../../unsubscribe.base';

@Component({
  selector: 'app-action-details-page',
  templateUrl: './action-details-page.component.html',
  styleUrls: ['./action-details-page.component.scss'],
})
export class ActionDetailsPageComponent
  extends UnsubscribeBase
  implements OnInit
{
  public action: Action;

  public collectedAmountPercentage: number;
  public remainingAmount: number;
  public remainingDays: number;

  public donationRows: Array<{
    name: string;
    amount: number;
    createdDate: Date;
  }> = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private actionService: ActionService,

    private appService: AppService,
    private authService: AuthService,
  ) {
    super();
  }

  ngOnInit(): void {
    const action$ = this.activatedRoute.params
      .pipe(
        switchMap((params) =>
          combineLatest([
            this.actionService.getAction(params.slug),
            this.authService.authorizedUser$,
          ]),
        ),
      )
      .subscribe(
        ([action, user]) => {
          if (user?._id !== action.creator._id) {
            const error = new Error('Not allowed');
            // @ts-ignore
            error.status = 404;
            throw error;
          }

          this.action = action;

          this.appService.setTitle(`${action.title} details | ActionPlatform`);
          this.appService.setDescription(action.description);
          this.appService.setImage(action.banner);
          this.appService.setUrl(this.router.url);

          this.collectedAmountPercentage =
            (action.collectedAmount / action.targetAmount) * 100;

          this.remainingAmount = Math.round(
            Math.max(0, action.targetAmount - action.collectedAmount),
          );

          const currentDate = new Date();
          const endDate = new Date(action.endDate);

          this.remainingDays = Math.max(
            0,
            Math.round(
              (endDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24),
            ),
          );

          const anonymousDonations: Array<number> = [];
          this.donationRows = action.donations
            .filter((item) => item.status === 'collected')
            .filter(({ name, amount }) => {
              if (!name) {
                anonymousDonations.push(amount);
              }
              return !!name;
            })
            .map(({ name, amount, createdAt }) => ({
              name,
              amount,
              createdDate: createdAt,
            }));

          if (anonymousDonations.length) {
            const name = 'Onbekend';
            let suffix = `(${anonymousDonations.length} donateur)`;
            if (anonymousDonations.length > 1) {
              suffix = `(${anonymousDonations.length} donateurs)`;
            }
            this.donationRows.push({
              name: [name, suffix].join(' '),
              amount: anonymousDonations.reduce((prev, curr) => prev + curr, 0),
              createdDate: new Date(action.startDate),
            });
          }
        },
        (error) => {
          if (error.status === 404) {
            this.router.navigate(['404']);
          }
        },
      );

    this.subscriptions.push(action$);
  }
}
