<div *ngIf="action" class="container action-details page">
  <div class="columns">
    <div class="column is-10 is-offset-1 is-10-mobile is-offset-1-mobile">
      <section class="section is-flex is-justify-content-center">
        <a [routerLink]="['/']"
          ><i class="icon fas fa-arrow-left"></i> Overzicht alle acties</a
        >
      </section>
      <section>
        <h1
          class="title is-size-1 is-flex is-justify-content-center has-text-centered"
        >
          {{ action.title }}
        </h1>
        <h2 class="subtitle is-size-4 has-text-centered">
          {{ action.description }}
        </h2>
        <h3 class="has-text-centered">
          {{ createdText }}
        </h3>
      </section>
      <section class="banner mt-4">
        <ng-container [ngSwitch]="true">
          <p
            class="has-text-centered has-text-weight-bold is-size-5"
            *ngSwitchCase="donationCount === 0"
          >
            Er zijn nog geen donateurs die deze actie hebben gesteund.
          </p>
          <p
            class="has-text-centered has-text-weight-bold is-size-5"
            *ngSwitchCase="donationCount === 1"
          >
            Deze actie wordt door 1 donateur gesteund.
          </p>
          <p
            class="has-text-centered has-text-weight-bold is-size-5"
            *ngSwitchDefault
          >
            Deze actie wordt door {{ donationCount }} donateurs gesteund.
          </p>
        </ng-container>
        <div>
          <figure class="image is-2by1 mt-5">
            <img src="{{ action.banner }}" alt="{{ action.title }}" />
          </figure>
          <div class="columns">
            <div
              class="column columns is-8-desktop is-11-tablet is-10-mobile is-offset-1-desktop is-offset-1-mobile action-details__details-block is-flex is-justify-content-space-between is-align-items-center is-flex-wrap-wrap"
            >
              <div class="column is-2-desktop is-2-tablet is-12-mobile">
                <app-day-counter
                  [remainingDays]="remainingDays"
                ></app-day-counter>
              </div>
              <div
                class="column is-6-desktop is-6-tablet progress-bar-container"
              >
                <app-progress-bar
                  [targetAmount]="action.targetAmount"
                  [collectedAmount]="action.collectedAmount"
                  [remainingAmount]="remainingAmount"
                  [collectedAmountPercentage]="collectedAmountPercentage"
                ></app-progress-bar>
              </div>
              <div
                class="column is-4-tablet is-12-mobile is-flex is-justify-content-center"
              >
                <a class="button is-alt-link" [routerLink]="['donate']"
                  >Steun deze actie</a
                >
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="columns">
        <div class="column is-10 is-offset-1">
          <h2
            class="subtitle has-text-centered has-text-weight-bold mb-2 has-text-black"
          >
            Steun en deel deze pagina met je vrienden en familie!
          </h2>
          <div class="is-flex is-justify-content-center">
            <app-share-buttons
              [routeUrl]="routeUrl"
              [action]="action"
            ></app-share-buttons>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-10 is-offset-1">
          <div
            class="action-content ngx-editor-content"
            *ngIf="action.content"
            [innerHTML]="action.content | safe : 'html'"
          ></div>
        </div>
      </div>

      <section *ngIf="campaign" class="campaign">
        <div class="columns is-offset-1-desktop">
          <div class="column is-10-desktop is-offset-1-desktop">
            <h2 class="title has-text-centered">Met deze actie steun je:</h2>
            <div class="is-relative">
              <a *ngIf="campaign.url" [href]="campaign.url" target="__blank">
                <figure class="image overlay">
                  <img src="{{ campaign.banner }}" alt="{{ campaign.title }}" />
                </figure>
                <h3 class="title support-action-banner__text has-text-white">
                  {{ action.campaign.title }}
                </h3>
              </a>
              <ng-container *ngIf="!campaign.url">
                <figure class="image overlay">
                  <img src="{{ campaign.banner }}" alt="{{ campaign.title }}" />
                </figure>
                <h3 class="title support-action-banner__text has-text-white">
                  {{ action.campaign.title }}
                </h3>
              </ng-container>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
