import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
  @Input() public collectedAmountPercentage: number;
  @Input() public remainingAmount: number;
  @Input() public targetAmount: number;
  @Input() public collectedAmount: number;

  public remainingPercentStyle: string;
  constructor() {}

  ngOnInit(): void {
    this.remainingPercentStyle = `${
      Math.min(this.collectedAmountPercentage, 100) - 13
    }%`;
  }
}
