<div class="action h-100">
  <div class="card h-100">
    <div class="card-image h-100 is-flex is-flex-direction-column">
      <div class="day-counter-container">
        <app-day-counter [remainingDays]="remainingDays"></app-day-counter>
      </div>
      <figure class="image is-4by3">
        <img src="{{ action.banner }}" alt="{{ action.title }}" />
      </figure>
      <app-progress-bar
        [remainingAmount]="remainingAmount"
        [targetAmount]="action.targetAmount"
        [collectedAmount]="action.collectedAmount"
        [collectedAmountPercentage]="collectedAmountPercentage"
      ></app-progress-bar>

      <div class="card-content is-flex-grow-2 is-flex is-flex-direction-column">
        <h2 class="title is-size-4 is-flex-grow-1">
          <a class="has-text-black" [routerLink]="['/actions', action.slug]">
            {{ action.title }}</a
          >
        </h2>
        <div
          *ngIf="!myAction; else myActionItem"
          class="content is-flex is-flex-direction-column is-flex-grow-2 is-justify-content-flex-end"
        >
          <p class="subtitle is-6">{{ action.creatorName }}</p>
          <div class="is-flex is-justify-content-space-between">
            <a [routerLink]="['/actions', action.slug]" class="">
              <span>Lees meer</span
              ><span class="icon">
                <i class="fas fa-chevron-right"></i>
              </span>
            </a>
            <a
              [routerLink]="['/actions', action.slug, 'donate']"
              class="button is-link"
            >
              <span class="icon"> <i class="fas fa-heart"></i> </span
              ><span>geef</span>
            </a>
          </div>
        </div>
        <ng-template #myActionItem>
          <div class="has-text-weight-semibold">
            <span class="icon mr-3 has-text-info">
              <i class="far fa-person-sign"></i>
            </span>
            <span>
              {{
                action.campaign ? action.campaign.title : 'Vervallen campagne'
              }}</span
            >
          </div>
          <div class="has-text-weight-semibold">
            <span
              class="icon mr-3"
              [ngClass]="action.published ? 'has-text-info' : 'has-text-grey'"
            >
              <i
                class=""
                [ngClass]="
                  action.published ? 'fas fa-rocket' : 'fal fa-hourglass-start'
                "
              ></i>
            </span>
            <span>
              {{ action.published ? 'Actief' : 'Opgeslagen als concept' }}</span
            >
          </div>
          <div class="has-text-weight-semibold">
            <span class="icon mr-3 has-text-info"
              ><i class="far fa-users"></i></span
            ><span>
              {{
                action.donations.length > 0
                  ? action.donations.length + ' Donateurs'
                  : 'Er zijn nog geen donateurs'
              }}
            </span>
          </div>
          <div class="mt-5">
            <a
              [routerLink]="['/actions', action.slug, 'edit']"
              class="w-100 button is-primary"
            >
              <span>Actie bewerken</span
              ><span class="icon">
                <i class="far fa-edit"></i>
              </span>
            </a>
          </div>
          <!-- Enable when the concept list is created -->
          <div class="mt-3" *ngIf="false">
            <button
              class="w-100 button is-info is-light"
              (click)="pauseMyAction()"
            >
              <span>Actie Pauzeren</span
              ><span class="icon">
                <i class="far fa-pause"></i>
              </span>
            </button>
          </div>
          <div class="mt-3">
            <a
              [routerLink]="['/actions', action.slug, 'details']"
              class="w-100 button is-link"
            >
              <span>Donateurs bekijken</span
              ><span class="icon">
                <i class="fas fa-money-bill-wave"></i>
              </span>
            </a>
          </div>
          <div class="mt-3">
            <a
              [routerLink]="['/actions', action.slug]"
              class="w-100 button is-info is-outlined"
            >
              <span>Bekijk op de website</span
              ><span class="icon">
                <i class="fas fa-chevron-right"></i>
              </span>
            </a>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
