import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class DonateService {
  constructor(private apiService: ApiService) {}

  public donate(
    slug: string,
    donation: {
      amount: number;
      frequency: string;
      method: string;
      methodRelated: Partial<{ iban: string; holder: string; bank: string }>;
      email: string;
      firstName: string;
      infix: string;
      lastName: string;
      showName: boolean;
    },
  ): Observable<{ redirectUrl: string }> {
    return this.apiService.post(`actions/${slug}/donate`, donation);
  }
}
