import { isPlatformServer } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Action } from 'src/app/interfaces/action.interface';

@Component({
  selector: 'app-share-buttons',
  templateUrl: './share-buttons.component.html',
  styleUrls: ['./share-buttons.component.scss'],
})
export class ShareButtonsComponent implements OnInit {
  @Input() public routeUrl: string;
  @Input() public action: Action;
  public _routeUrl: string;

  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {}

  ngOnInit(): void {
    this._routeUrl = encodeURIComponent(this.routeUrl);
  }

  share(social: 'facebook' | 'twitter' | 'linkedin' | 'whatsapp') {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    let url;
    switch (social) {
      case 'facebook':
        url = `https://www.facebook.com/sharer/sharer.php?u=${this._routeUrl}`;
        break;
      case 'twitter':
        url = `https://twitter.com/intent/tweet?text=Doe je ook mee met de actie: ${this.action.title}? Ga dan naar deze link: ${this.routeUrl}?url=${this._routeUrl}`;
        break;
      case 'linkedin':
        url = `https://www.linkedin.com/shareArticle?url=${this._routeUrl}&mini=true`;
        break;
      case 'whatsapp':
        url = `https://wa.me/?text=Doe je ook mee met de actie: ${this.action.title}? Ga dan naar deze link: ${this.routeUrl}`;
        break;
      default:
        break;
    }
    window.open(
      url,
      'targetWindow',
      'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=550,height=550',
    );
  }
}
