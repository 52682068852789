<div class="container page">
  <div class="columns is-centered is-flex flex-wrap">
    <div
      class="column is-8-desktop is-10-tablet is-10-mobile is-offset-1-mobile"
    >
      <h1 class="title is-1 has-text-centered mt-5">Mijn Acties</h1>
      <h2 class="title is-4 has-text-centered">
        Wil je een actie aanmaken of een actie die je al eerder hebt gemaakt
        bewerken? Maak dan een account of log hier in.
      </h2>
    </div>
  </div>
  <div class="columns is-centered">
    <div
      class="column is-5-desktop is-6-tablet is-10-mobile is-offset-1-mobile"
    >
      <div class="box has-background-primary-light">
        <app-register [noPassword]="useMagicLink"></app-register>
      </div>
    </div>
    <div
      class="column is-5-desktop is-6-tablet is-10-mobile is-offset-1-mobile"
    >
      <div class="box">
        <app-login [noPassword]="useMagicLink"></app-login>
      </div>
    </div>
  </div>
</div>
