<div class="container page">
  <div class="columns is-centered" *ngIf="campaigns">
    <div
      class="column is-8-desktop is-10-tablet is-10-mobile is-offset-1-mobile card my-5"
    >
      <div class="form">
        <h2 class="title is-2">
          Stap 1: Kies de campagne die jij wilt steunen
        </h2>
        <div *ngIf="activeStep === 1">
          <div class="field">
            <label for="campaign" class="label">
              Welke campagne wil jij steunen?
            </label>
            <div class="control">
              <div class="select">
                <select
                  name="campaign"
                  [(ngModel)]="selectedCampaign"
                  [compareWith]="compareCampaigns"
                >
                  <option [value]="undefined">Selecteer een campagne...</option>
                  <ng-container *ngFor="let campaign of campaigns">
                    <option [ngValue]="campaign" *ngIf="campaign.published">
                      {{ campaign.title }}
                    </option>
                  </ng-container>
                </select>
              </div>
            </div>
          </div>
          <div class="field is-grouped">
            <div class="control">
              <button
                [disabled]="!selectedCampaign"
                class="button is-link"
                (click)="switchStep(2)"
              >
                Verder
              </button>
            </div>
            <div class="control">
              <a class="button is-link is-light" [routerLink]="['/']"
                >Annuleren</a
              >
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="activeStep === 2 && selectedCampaign" class="control mt-5">
        <p class="label">
          Je hebt gekozen voor: "{{ selectedCampaign.title }}"
        </p>
        <button
          *ngIf="formMode === 'create' && !hideCampaignChanger"
          class="button is-link is-inverted"
          (click)="switchStep(1)"
          [disabled]="isLoading"
        >
          <span class="icon"> <i class="fas fa-chevron-left"></i> </span
          ><span> Selecteer een andere campagne</span>
        </button>
      </div>
    </div>
  </div>
  <!-- STEP 2 -->
  <form
    *ngIf="actionForm && activeStep === 2"
    [formGroup]="actionForm"
    (ngSubmit)="onSubmit()"
  >
    <div class="columns is-centered">
      <div
        class="column is-8-desktop is-10-tablet is-10-mobile is-offset-1-mobile card my-5"
      >
        <h2 class="title is-2">Stap 2: De actie en het doel</h2>
        <div class="form">
          <div class="field">
            <label for="title" class="label"
              >Geef je actie een compacte en aansprekende titel*</label
            >
            <div class="control">
              <input
                class="input"
                type="text"
                name="title"
                placeholder="Titel van de actie"
                formControlName="title"
                id="title"
              />
            </div>
            <p
              class="help is-danger is-size-6 has-text-weight-bold"
              *ngIf="
                actionForm.controls.title.touched &&
                actionForm.controls.title.errors?.required
              "
            >
              De titel is verplicht
            </p>
            <p
              class="help is-danger is-size-6 has-text-weight-bold"
              *ngIf="actionForm.controls.title.errors?.maxlength"
            >
              De titel is te lang
            </p>
          </div>
          <br />
          <div class="field">
            <label class="label" for="content"
              >Vertel het verhaal van jouw actie!</label
            >
            <p>
              Vertel de mensen die je willen steunen waarom je deze actie wilt
              starten. Heb je een persoonlijk verhaal dat je wilt vertellen of
              ben je geraakt door de campagne?
            </p>
            <br />
            <div class="control">
              <div class="ngxeditor-wrapper">
                <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
                </ngx-editor-menu>
                <ngx-editor
                  [editor]="editor"
                  formControlName="content"
                  [placeholder]="'Begin hier met typen'"
                  class="editor"
                >
                </ngx-editor>
              </div>
            </div>
          </div>

          <br />

          <div class="field">
            <label class="label" for="targetAmount"
              >Wat is jouw streefbedrag? (in hele euro’s)</label
            >
            <div class="control">
              <input
                class="input"
                type="number"
                name="targetAmount"
                placeholder="1000,-"
                formControlName="targetAmount"
              />
            </div>
          </div>
          <br />
          <ng-container *ngIf="!hideTargetChanger">
            <div class="field">
              <label class="label" for="target"
                >Voor wie haal je geld op?</label
              >
              <div class="control">
                <div class="select">
                  <select name="target" formControlName="target">
                    <option value="">Selecteer een ontvanger</option>
                    <option
                      *ngFor="let target of selectedCampaign.targets"
                      [value]="target.value"
                    >
                      {{ target.label }}
                    </option>
                  </select>
                </div>
                <p
                  class="help is-danger is-size-6 has-text-weight-bold"
                  *ngIf="
                    actionForm.controls.target.touched &&
                    !actionForm.controls.target.valid
                  "
                >
                  Een target is verplicht
                </p>
              </div>
            </div>
            <br />
          </ng-container>

          <div class="field">
            <label class="label" for="startDate">
              Wanneer start de actie?
            </label>
            <div class="control">
              <input
                type="date"
                class="input"
                id="start"
                name="startDate"
                max="{{ selectedCampaign.endDate | date : 'YYYY-MM-dd' }}"
                formControlName="startDate"
              />
            </div>
            <p class="help">
              Kies de datum van vandaag als startdatum. Zo is jouw actie meteen
              te zien op het actieplatform
            </p>
          </div>

          <br />
          <div class="field">
            <div class="field">
              <label class="label" for="endDate">
                Wanneer stopt de actie?
              </label>
              <div class="control">
                <input
                  class="input"
                  type="date"
                  name="endDate"
                  min="{{ today | date : 'YYYY-MM-dd' }}"
                  formControlName="endDate"
                />
              </div>
            </div>
          </div>

          <br />
          <h3 class="has-text-weight-semibold">
            Als jij andere bedragen wilt voorstellen dan kun je dat hier
            instellen.
          </h3>
          <p class="mb-5">
            De onderstaande bedragen zijn de standaard bedragen voor deze
            campagne. Als jij andere bedragen wil voorstellen aan jouw sponsors
            dan kan je dat hier aangeven. Er is voor bezoekers altijd een vrij
            invulveld beschikbaar.
          </p>
          <div formGroupName="suggestedAmounts">
            <!-- Loop over suggestedAmounts -->
            <div
              *ngFor="let id of suggestedAmountKeys; let i = index"
              class="control mb-3"
            >
              <label class="label" [for]="'suggestedAmount' + id"
                >Bedrag {{ i + 1 }}</label
              >
              <input
                class="input"
                type="number"
                [name]="'suggestedAmount' + id"
                [formControlName]="id"
              />
              <p
                class="help is-danger is-size-6 has-text-weight-bold"
                *ngIf="!getSuggestedAmountValid(id)"
              >
                Dit bedrag is onder het minimum bedrag van €
                {{ selectedCampaign.minimumAmount }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- STEP 3 -->
    <div class="columns is-centered">
      <div
        class="column is-8-desktop is-10-tablet is-10-mobile is-offset-1-mobile card my-5"
      >
        <div class="form">
          <h2 class="title is-2">Stap 3: Wie organiseert deze actie?</h2>

          <div class="field">
            <label class="label"
              >Naam van de actie organisator (persoon, bedrijf of
              organisatie)</label
            >
            <div class="control">
              <input
                class="input"
                type="text"
                name="actieTitel"
                placeholder="Bijv. Jansen Glazenwassers"
                formControlName="creatorName"
                id="creatorName"
              />
            </div>
            <p
              class="help is-danger is-size-6 has-text-weight-bold"
              *ngIf="
                actionForm.controls.creatorName.touched &&
                !actionForm.controls.creatorName.valid
              "
            >
              Naam is verplicht
            </p>
          </div>
          <br />
          <app-file-upload
            [fileUrl]="bannerUrl"
            (fileUrlEmitter)="getFileUrl($event)"
          ></app-file-upload>
          <p
            class="help is-danger is-size-6 has-text-weight-bold"
            *ngIf="
              actionForm.controls.banner.touched &&
              !actionForm.controls.banner.valid
            "
          >
            Bannerfoto is verplicht
          </p>
          <ng-container *ngIf="selectedCampaign.groups.length">
            <br />
            <div class="field">
              <label class="label" for="group">
                Geef aan of je aangesloten bent bij een groep
              </label>
              <div class="control">
                <div class="select w-100">
                  <select name="group" formControlName="group" class="w-100">
                    <option value="">
                      Ik ben niet aangesloten bij een groep
                    </option>
                    <option
                      *ngFor="let group of selectedCampaign.groups"
                      [value]="group.value"
                    >
                      {{ group.label }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </ng-container>
          <br />
          <div class="field">
            <label class="label" for="description"
              >Korte omschrijving van de actie</label
            >
            <div class="control">
              <textarea
                class="textarea"
                placeholder="Deze beschrijving wordt zichtbaar op de actiepagina"
                name="description"
                formControlName="description"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns is-centered">
      <div
        class="column is-8-desktop is-10-tablet is-10-mobile is-offset-1-mobile my-5"
      >
        <div class="field is-grouped">
          <div class="control">
            <button
              type="submit"
              value="Send"
              class="button is-link"
              [disabled]="actionForm.invalid || isLoading"
            >
              {{
                formMode === 'create' ? 'Maak mijn actie aan' : 'Actie opslaan'
              }}
            </button>
          </div>
          <div class="control">
            <a class="button is-link is-light" [routerLink]="['/']"
              >Annuleren</a
            >
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
