import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Action } from '../../interfaces/action.interface';
import { ActionService } from '../../services/action.service';
import { AppService } from '../../services/app.service';
import { UnsubscribeBase } from '../../unsubscribe.base';

@Component({
  selector: 'app-donation-end-page',
  templateUrl: './donation-end-page.component.html',
  styleUrls: ['./donation-end-page.component.scss'],
})
export class DonationEndPageComponent
  extends UnsubscribeBase
  implements OnInit
{
  public status: 'success' | 'error';
  public action: Action;
  public collectedAmountPercentage: number;
  public remainingAmount: number;
  public remainingDays: number;
  public routeUrl: string;
  public amount: number;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private route: ActivatedRoute,
    private actionService: ActionService,
    private appService: AppService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    super();
  }

  ngOnInit(): void {
    const params$ = this.activatedRoute.queryParams;

    const action$ = this.activatedRoute.data.pipe(
      switchMap((data) => {
        this.status =
          data && data.donation && data.donation.status === 'success'
            ? 'success'
            : 'error';
        return this.activatedRoute.params;
      }),
      switchMap((params) => this.actionService.getAction(params.slug)),
    );

    const combined$ = combineLatest([params$, action$]).subscribe(
      ([params, action]) => {
        this.action = action;
        this.appService.setTitle(`Donation ${this.status} | ActionPlatform`);
        this.appService.setDescription(this.action.description);
        this.appService.setImage(this.action.banner);
        this.appService.setUrl(this.router.url);
        if (isPlatformBrowser(this.platformId)) {
          this.routeUrl = `${this.document.location.origin}/actions/${this.action.slug}`;
        } else {
          this.routeUrl = '';
        }
        if (typeof params.amount === 'number') {
          this.amount = params.amount;
        } else {
          this.amount = Number(params.amount);
        }
      },
      (error) => {
        if (error.status === 404) {
          this.router.navigate(['404']);
        }
      },
    );

    this.subscriptions.push(combined$);
  }
}
