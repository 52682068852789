<footer class="footer">
  <div class="content has-text-centered">
    <nav class="navbar is-primary is-spaced" aria-label="footer navigation">
      <div class="navbar-brand">
        <a class="navbar-item" [routerLink]="['/']">
          Dit is een initiatief van
          <img
            src="../../../assets/images/footer-logo.png"
            style="padding-left: 1rem; padding-right: 3rem"
            alt=""
          />
        </a>
      </div>

      <div class="navbar-menu">
        <div class="navbar-start">
          <!-- <a
            class="navbar-item is-tab mr-5"
            [routerLink]="['/create']"
            routerLinkActive="link-active"
            ><span class="icon">
              <i class="fas fa-chevron-right mr-3"></i>
            </span>
            Campagnes
          </a> -->

          <a
            class="navbar-item is-tab mr-5"
            [routerLink]="['/']"
            routerLinkActive="link-active"
            ><span class="icon">
              <i class="fas fa-chevron-right mr-3"></i>
            </span>
            Acties
          </a>

          <!--  <a
            class="navbar-item is-tab mr-5"
            [routerLink]="['/create']"
            routerLinkActive="link-active"
            ><span class="icon">
              <i class="fas fa-chevron-right mr-3"></i>
            </span>
            Vragen?
          </a> -->

          <div class="navbar-item">
            <a
              class="button is-primary"
              [routerLink]="['/register']"
              routerLinkActive="link-active"
              *ngIf="!(authService.authStatus$ | async)"
            >
              <strong>Word actievoerder</strong>
            </a>
          </div>
          <div class="navbar-item">
            <a
              class="button"
              routerLinkActive="link-active"
              *ngIf="authService.authStatus$ | async"
              (click)="logOut()"
            >
              <strong>Uitloggen</strong>
            </a>
          </div>
          <div class="navbar-item">
            <a
              class="button"
              [routerLink]="['/login']"
              routerLinkActive="link-active"
              *ngIf="!(authService.authStatus$ | async)"
            >
              Inloggen
            </a>
          </div>
        </div>

        <div class="navbar-end">
          <!--  <a class="navbar-item is-tab">@2021</a>
          <a
            class="navbar-item is-tab"
            [routerLink]="['']"
            routerLinkActive="link-active"
          >
            Cookies
          </a> -->
          <a
            [href]="this.privacyPolicyUrl"
            target="_blank"
            class="navbar-item is-tab"
          >
            Privacy Policy
          </a>
          <a
            [href]="this.termsAndConditionsUrl"
            target="_blank"
            class="navbar-item is-tab"
          >
            Algemene voorwaarden
          </a>
        </div>
      </div>
    </nav>
  </div>
</footer>
