import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Action } from '../../interfaces/action.interface';

@Component({
  selector: 'app-action-list-item',
  templateUrl: './action-list-item.component.html',
  styleUrls: ['./action-list-item.component.scss'],
})
export class ActionListItemComponent implements OnInit {
  @Input() public action: Action;
  @Input() public myAction = false;
  @Output() pauseMyActionEmit = new EventEmitter<string>();
  public collectedAmountPercentage: number;
  public remainingAmount: number;
  public remainingDays: number;
  constructor() {}

  ngOnInit(): void {
    this.collectedAmountPercentage =
      (this.action.collectedAmount / this.action.targetAmount) * 100;
    this.remainingAmount = Math.max(
      0,
      this.action.targetAmount - this.action.collectedAmount,
    );

    const currentDate = new Date();
    const endDate = new Date(this.action.endDate);
    this.remainingDays = Math.max(
      0,
      Math.round(
        (endDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24),
      ),
    );
  }

  pauseMyAction() {
    this.pauseMyActionEmit.emit(this.action._id);
  }
}
