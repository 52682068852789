import { CommonModule, registerLocaleData } from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import localNl from '@angular/common/locales/nl';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Meta, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxEditorModule } from 'ngx-editor';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ActionFormComponent } from './components/action-form/action-form.component';
import { ActionListItemComponent } from './components/action-list-item/action-list-item.component';
import { DayCounterComponent } from './components/day-counter/day-counter.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LoginComponent } from './components/login/login.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { RegisterComponent } from './components/register/register.component';
import { ShareButtonsComponent } from './components/share-buttons/share-buttons.component';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { ActionDetailPageComponent } from './pages/action-detail-page/action-detail-page.component';
import { ActionDetailsPageComponent } from './pages/action-details-page/action-details-page.component';
import { CreateActionPageComponent } from './pages/create-action-page/create-action-page.component';
import { DonatePageComponent } from './pages/donate-page/donate-page.component';
import { DonationEndPageComponent } from './pages/donation-end-page/donation-end-page.component';
import { EditActionPageComponent } from './pages/edit-action-page/edit-action-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { LoginMagicLinkPageComponent } from './pages/login-magic-link-page/login-magic-link-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { LoginRegisterPageComponent } from './pages/login-register-page/login-register-page.component';
import { MyActionsComponent } from './pages/my-actions-page/my-actions.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { RegisterActivationPageComponent } from './pages/register-activation-page/register-activation-page.component';
import { RegisterPageComponent } from './pages/register-page/register-page.component';
import { PricePipe } from './pipes/price.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { ApiService } from './services/api.service';
import { AppService } from './services/app.service';
import { Angulartics2Module } from 'angulartics2';
registerLocaleData(localNl);

export function initFactory(appService: AppService) {
  return () => appService.initPlatformSettings();
}

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    MyActionsComponent,
    ActionListItemComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    LoginRegisterPageComponent,
    LoginPageComponent,
    RegisterPageComponent,
    LoginMagicLinkPageComponent,
    RegisterComponent,
    RegisterActivationPageComponent,
    ActionDetailPageComponent,
    ActionDetailsPageComponent,
    DonatePageComponent,
    NotFoundPageComponent,
    CreateActionPageComponent,
    EditActionPageComponent,
    ShareButtonsComponent,
    ProgressBarComponent,
    DayCounterComponent,
    FileUploadComponent,
    SafePipe,
    PricePipe,
    DonationEndPageComponent,
    ActionFormComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule.withServerTransition({ appId: 'action-platform' }),
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    NgxEditorModule.forRoot({
      locals: {
        bold: 'Dikgedrukt',
        italic: 'Schuingedrukt',
        underline: 'Onderstreept',
        heading: 'Kop',
        h2: 'Kop 2',
        h3: 'Kop 3',
        url: 'Link',
        text: 'Tekst',
        openInNewTab: 'Open in nieuw tab',
        insert: 'Invoegen',
      },
    }),
    Angulartics2Module.forRoot(),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: initFactory,
      deps: [AppService, Title, Meta, ApiService, HttpClient],
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'en-US' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
