<div *ngIf="action" class="container action-details page">
  <div class="columns">
    <div class="column is-10 is-offset-1 is-10-mobile is-offset-1-mobile">
      <section class="section is-flex is-justify-content-center">
        <a [routerLink]="['/my-actions']"
          ><i class="icon fas fa-arrow-left"></i> Overzicht mijn acties</a
        >
      </section>
      <section>
        <h1
          class="title is-size-1 is-flex is-justify-content-center has-text-centered"
        >
          {{ action.title }}
        </h1>
      </section>
      <section class="banner mt-4">
        <div>
          <figure class="image is-2by1 mt-5">
            <img src="{{ action.banner }}" alt="{{ action.title }}" />
          </figure>
          <div class="columns">
            <div
              class="column columns is-8-desktop is-11-tablet is-10-mobile is-offset-1-desktop is-offset-1-mobile action-details__details-block is-flex is-justify-content-space-between is-align-items-center is-flex-wrap-wrap"
            >
              <div class="column is-2-desktop is-2-tablet is-12-mobile">
                <app-day-counter
                  [remainingDays]="remainingDays"
                ></app-day-counter>
              </div>
              <div
                class="column is-10-desktop is-10-tablet is-12-mobile progress-bar-container"
              >
                <app-progress-bar
                  [targetAmount]="action.targetAmount"
                  [collectedAmount]="action.collectedAmount"
                  [remainingAmount]="remainingAmount"
                  [collectedAmountPercentage]="collectedAmountPercentage"
                ></app-progress-bar>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section *ngIf="donationRows.length" class="donations pt-4">
        <div class="columns is-offset-1-desktop">
          <div class="column is-10-desktop is-offset-1-desktop">
            <h2 class="title has-text-centered">
              De volgende mensen hebben je actie gesteund:
            </h2>
            <table
              class="table w-100"
              aria-describedby="List of given donations"
            >
              <thead>
                <tr>
                  <th scope="col">Naam</th>
                  <th scope="col">Bedrag</th>
                  <th scope="col">Datum</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let donation of donationRows; let odd = odd"
                  [ngClass]="{ 'has-background-grey-lighter': odd }"
                >
                  <th scope="col">{{ donation.name }}</th>
                  <td>€ {{ donation.amount | price : 'nl-NL' }}</td>
                  <td>
                    {{
                      donation.createdDate | date : 'd MMMM YYYY' : '' : 'nl-NL'
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
      <section *ngIf="!donationRows.length" class="donations pt-4">
        <div class="columns is-offset-1-desktop">
          <div class="column is-10-desktop is-offset-1-desktop">
            <h2 class="title has-text-centered">
              Er zijn nog geen donateurs bekend
            </h2>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
