import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { UnsubscribeBase } from 'src/app/unsubscribe.base';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent extends UnsubscribeBase implements OnInit {
  privacyPolicyUrl: string;
  termsAndConditionsUrl: string;

  constructor(public authService: AuthService, public appService: AppService) {
    super();
  }

  ngOnInit(): void {
    const policyAgreementData$ = this.appService.platformSettings$.subscribe(
      (data) => {
        this.privacyPolicyUrl = data.privacyPolicy || '';
        this.termsAndConditionsUrl = data.termsAndConditions || '';
      },
    );

    this.subscriptions.push(policyAgreementData$);
  }

  logOut() {
    this.authService.logOut();
  }
}
