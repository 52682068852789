import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject, throwError } from 'rxjs';
import { delay, switchMap, tap } from 'rxjs/operators';
import { User } from '../interfaces/user.interface';
import { ApiService } from './api.service';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  static readonly STORAGE = {
    TOKEN: 'token',
    REFRESHTOKEN: 'refreshtoken',
  };

  private authStatusSub = new ReplaySubject<boolean>(1);

  public readonly authStatus$ = this.authStatusSub.asObservable();

  private authorizedUserSub = new ReplaySubject<User | undefined>(1);

  public readonly authorizedUser$ = this.authorizedUserSub.asObservable();

  constructor(
    private apiService: ApiService,
    private cacheService: CacheService,
  ) {
    const tokenExists = this.cacheService.exists(AuthService.STORAGE.TOKEN);
    const refreshTokenExists = this.cacheService.exists(
      AuthService.STORAGE.REFRESHTOKEN,
    );
    if (tokenExists && refreshTokenExists) {
      of({})
        .pipe(
          delay(1),
          switchMap(() => this.getAuthorizedUser()),
        )
        .subscribe(
          (user) => {
            this.authorizedUserSub.next(user);
            this.authStatusSub.next(true);
          },
          (error) => {
            this.authorizedUserSub.next(undefined);
            this.authStatusSub.next(false);
          },
        );
    } else {
      this.authStatusSub.next(false);
      this.authorizedUserSub.next(undefined);
    }
  }

  private getAuthorizedUser() {
    return this.apiService.get<User>('auth/users/me');
  }

  public logIn(
    {
      username,
      password,
    }: {
      username: string;
      password: string;
    },
    magicLink: boolean = false,
  ): Observable<User | string> {
    if (!magicLink) {
      return this.loginUsernamePassword({ username, password });
    }
    return this.apiService.post<string>(
      'login/magic-link',
      {
        username,
        password,
      },
      { responseType: 'text' as 'json' },
    );
  }

  public loginMagicLink(code: string) {
    return this.apiService
      .post<{ token: string; refreshToken: string }>('login/magic-link/use', {
        code,
      })
      .pipe(
        switchMap((data) => {
          if (data && data.token && data.refreshToken) {
            this.cacheService.set(AuthService.STORAGE.TOKEN, data.token);
            this.cacheService.set(
              AuthService.STORAGE.REFRESHTOKEN,
              data.refreshToken,
            );
            this.authStatusSub.next(true);
          }
          return this.getAuthorizedUser();
        }),
        tap((user) => {
          this.authorizedUserSub.next(user);
        }),
      );
  }

  private loginUsernamePassword({
    username,
    password,
  }: {
    username: string;
    password: string;
  }): Observable<User> {
    return this.apiService
      .post<{ token: string; refreshToken: string }>('login', {
        username,
        password,
      })
      .pipe(
        switchMap((data) => {
          if (data && data.token && data.refreshToken) {
            this.cacheService.set(AuthService.STORAGE.TOKEN, data.token);
            this.cacheService.set(
              AuthService.STORAGE.REFRESHTOKEN,
              data.refreshToken,
            );
            this.authStatusSub.next(true);
          }
          return this.getAuthorizedUser();
        }),
        tap((user) => {
          this.authorizedUserSub.next(user);
        }),
      );
  }

  public logOut(): void {
    this.cacheService.clear();
    this.authStatusSub.next(false);
    this.authorizedUserSub.next(undefined);
    // TODO: Pick logout route
  }

  public register({
    username,
    password,
    displayName = '',
    firstName = '',
    lastName = '',
    gender = 'unknown',
    infix = '',
  }: {
    username: string;
    password: string;
    displayName?: string;
    firstName: string;
    lastName: string;
    gender: 'Male' | 'Female' | 'unknown';
    infix: string;
  }): Observable<User> {
    return this.apiService.post<User>('register', {
      username,
      password,
      displayName,
      extra: { firstName, lastName, gender, infix },
    });
  }

  public refreshToken() {
    const token = this.cacheService.get<string>(AuthService.STORAGE.TOKEN);
    const refreshToken = this.cacheService.get<string>(
      AuthService.STORAGE.REFRESHTOKEN,
    );

    if (!token) {
      return throwError('noToken');
    }

    if (!refreshToken) {
      return throwError('noRefreshToken');
    }

    return this.apiService
      .post<{ token: string }>('refresh', {
        token,
        refreshToken,
      })
      .pipe(
        tap((result) => {
          const { token } = result;

          this.cacheService.set(AuthService.STORAGE.TOKEN, token);
        }),
      );
  }

  public activate(hash: string): Observable<User> {
    return this.apiService.post<User>('activate', {
      hash,
    });
  }
}
