<div class="container page">
  <form
    [formGroup]="donateForm"
    (ngSubmit)="onSubmit()"
    *ngIf="action"
    class="form"
  >
    <h1 class="title is-1 has-text-centered mt-5">
      Wat fijn dat je de actie: " {{ action.title }}" wilt steunen!
    </h1>
    <div class="columns is-centered mt-5">
      <div
        class="column is-8-desktop is-10-tablet is-10-mobile is-offset-1-mobile card my-5"
      >
        <h4 class="title is-3 has-text-centered">Stap 1 - Kies het bedrag</h4>
        <div *ngIf="action.suggestedAmounts.length > 0" class="level-item">
          <div
            *ngFor="let amount of action.suggestedAmounts; let i = index"
            class="control"
          >
            <div class="field is-grouped">
              <label class="radio">
                <input
                  name="amount"
                  type="radio"
                  [value]="amount"
                  class="is-hidden"
                  formControlName="amount"
                />
                <div
                  class="button mr-5"
                  [ngClass]="{
                    'is-info':
                      amount === donateForm.get('amount')?.value &&
                      !donateForm.get('otherAmount')?.value
                  }"
                >
                  € {{ amount }}
                </div>
              </label>
            </div>
          </div>
        </div>

        <h4 class="title is-4 has-text-centered mt-5">Of</h4>
        <div class="field columns is-centered">
          <div
            class="control column is-8-desktop is-10-tablet is-10-mobile is-offset-1-mobile"
          >
            <input
              id="otherAmount"
              class="input"
              name="otherAmount"
              type="number"
              placeholder="€ Vul een bedrag in"
              formControlName="otherAmount"
            />
          </div>
        </div>
      </div>
    </div>
    <!--------------------------------------------
    //---------------STEP 2----------------
    //------------------------------------//-->
    <div class="columns is-centered">
      <div
        class="column is-8-desktop is-10-tablet is-10-mobile is-offset-1-mobile card my-5 has-text-centered"
      >
        <h4 class="title is-3">Stap 2a - Kies je betaal methode</h4>
        <div *ngIf="paymentMethods.length > 0" class="level-item mb-5">
          <div *ngFor="let method of paymentMethods" class="control">
            <label class="radio" *ngIf="method.enabled">
              <input
                name="paymentMethod"
                type="radio"
                [value]="method.value"
                class="is-hidden"
                formControlName="paymentMethod"
              />
              <a
                class="button mr-5"
                [ngClass]="{
                  'is-info':
                    method.value === donateForm.get('paymentMethod')?.value
                }"
                >{{ method.label }}</a
              >
            </label>
          </div>
        </div>

        <div formGroupName="methodRelated">
          <ng-container [ngSwitch]="donateForm.get('paymentMethod')?.value">
            <ng-container *ngSwitchCase="'Ideal'">
              <h5 class="title is-4">Stap 2b - Kies je bank</h5>
              <div class="field">
                <div class="control">
                  <div class="select">
                    <select formControlName="bank" name="bank">
                      <option hidden [value]="''">Selecteer een bank</option>
                      <option *ngFor="let bank of banks" [value]="bank.value">
                        {{ bank.label }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'Direct debit'">
              <div class="columns is-centered">
                <div
                  class="column is-8-desktop is-10-tablet is-10-mobile is-offset-1-mobile my-5"
                >
                  <h5 class="title is-4">
                    Stap 2b - Vul jouw IBAN en de naam van de rekeninghouder in
                  </h5>
                  <div class="field">
                    <div class="control">
                      <input
                        id="iban"
                        name="iban"
                        type="text"
                        placeholder="IBAN"
                        formControlName="iban"
                        class="input"
                      />
                    </div>
                    <p
                      class="help is-danger is-size-6 has-text-weight-bold"
                      *ngIf="
                        donateForm.get('methodRelated')?.get('iban')?.touched &&
                        (donateForm.errors?.ibanRequired ||
                          donateForm.get('methodRelated')?.get('iban')?.errors
                            ?.ibanInvalid)
                      "
                    >
                      Vul een correct IBAN nummer in
                    </p>
                  </div>
                  <div class="field">
                    <div class="control">
                      <input
                        id="holder"
                        name="holder"
                        type="text"
                        placeholder="Naam rekeninghouder"
                        formControlName="holder"
                        class="input"
                      />
                    </div>
                    <p
                      class="help is-danger is-size-6 has-text-weight-bold"
                      *ngIf="
                        donateForm.get('methodRelated')?.get('holder')
                          ?.touched && donateForm.errors?.holderRequired
                      "
                    >
                      Vul een correcte naam in
                    </p>
                  </div>
                  <div class="field">
                    <div class="control checkbox">
                      <input
                        type="checkbox"
                        formControlName="directDebitAgreement"
                        id="directDebitAgreement"
                      />
                      <label for="directDebitAgreement">
                        Ik geef toestemming om incasso-opdrachten te sturen naar
                        mijn bank om het bedrag van mijn rekening af te
                        schrijven.</label
                      >
                    </div>
                    <p
                      class="help is-danger is-size-6 has-text-weight-bold"
                      *ngIf="
                        donateForm.get('methodRelated')?.get('holder')
                          ?.touched &&
                        donateForm.get('methodRelated')?.get('iban')?.touched &&
                        donateForm.errors?.directDebitAgreementRequired
                      "
                    >
                      Dit veld is verplicht
                    </p>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'Creditcard'">
              <h5 class="title is-5">
                Je hebt gekozen voor een betaling met Creditcard
              </h5>
            </ng-container>
            <ng-container *ngSwitchDefault>KOMT NIET VOOR</ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <!--------------------------------------------
    //---------------STEP 3----------------
    //------------------------------------//-->
    <div class="columns is-centered">
      <div
        class="column is-8-desktop is-10-tablet is-10-mobile is-offset-1-mobile card my-5"
      >
        <h4 class="title is-3 has-text-centered">
          Stap 3 - Jouw naam en e-mailadres
        </h4>
        <div class="field columns is-centered">
          <div
            class="control column is-8-desktop is-10-tablet is-10-mobile is-offset-1-mobile"
          >
            <p>
              Jouw naam en e-mailadres worden gebruikt om de betaling te
              verwerken en om jou een bevestiging te kunnen sturen.
            </p>
            <div class="field mt-5">
              <label class="label">* Jouw naam</label>
              <div class="control">
                <div class="field is-grouped">
                  <div class="mr-5">
                    <input
                      id="firstName"
                      class="input"
                      name="firstName"
                      type="text"
                      placeholder="Voornaam"
                      formControlName="firstName"
                    />
                  </div>
                  <p
                    class="help is-danger is-size-6 has-text-weight-bold"
                    *ngIf="
                      donateForm.get('firstName')?.touched &&
                      donateForm.get('firstName')?.errors
                    "
                  >
                    Naam is verplicht
                  </p>
                  <div class="mr-5">
                    <input
                      id="infix"
                      class="input"
                      name="infix"
                      type="text"
                      placeholder="Tussenvoegsel"
                      formControlName="infix"
                    />
                  </div>
                  <p
                    class="help is-danger is-size-6 has-text-weight-bold"
                    *ngIf="
                      donateForm.get('infix')?.touched &&
                      donateForm.get('infix')?.errors
                    "
                  >
                    Naam is verplicht
                  </p>

                  <div>
                    <input
                      id="lastName"
                      class="input"
                      name="lastName"
                      type="text"
                      placeholder="Achternaam"
                      formControlName="lastName"
                    />
                  </div>
                  <p
                    class="help is-danger is-size-6 has-text-weight-bold"
                    *ngIf="
                      donateForm.get('lastName')?.touched &&
                      donateForm.get('lastName')?.errors
                    "
                  >
                    Achternaam is verplicht
                  </p>
                </div>
              </div>
              <div class="field mt-2">
                <div class="control checkbox">
                  <input
                    type="checkbox"
                    formControlName="showName"
                    id="showName"
                  />
                  <label for="showName" class="ml-3"
                    >De actievoerder mag weten wie ik ben</label
                  >
                </div>
              </div>
              <div class="field mt-5">
                <label class="label">* E-mailadres</label>
                <div class="control">
                  <input
                    id="email"
                    class="input"
                    name="email"
                    type="email"
                    placeholder="E-mailadres"
                    formControlName="email"
                  />
                </div>
                <p
                  class="help is-danger is-size-6 has-text-weight-bold"
                  *ngIf="
                    donateForm.get('email')?.touched &&
                    donateForm.get('email')?.errors
                  "
                >
                  E-mailadres is verplicht
                </p>
              </div>
              <div class="field mt-2">
                <div class="control checkbox">
                  <input
                    type="checkbox"
                    formControlName="generalAgreement"
                    id="generalAgreement"
                  />
                  <label
                    for="generalAgreement"
                    class="ml-3"
                    [innerHTML]="agreementText"
                  >
                  </label>
                </div>
                <p
                  class="help is-danger is-size-6 has-text-weight-bold"
                  *ngIf="
                    donateForm.get('generalAgreement')?.touched &&
                    donateForm.get('generalAgreement')?.errors
                  "
                >
                  Dit veld is verplicht
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--------------------------------------------
    //---------------STEP 4----------------
    //------------------------------------//-->
    <div class="columns is-centered">
      <div
        class="column is-8-desktop is-10-tablet is-10-mobile is-offset-1-mobile card my-5 has-text-centered"
      >
        <h4 class="title is-3">Stap 4 - Bevestiging en betalen</h4>
        <h3
          *ngIf="
            donateForm.get('amount')?.value ||
            donateForm.get('otherAmount')?.value
          "
          class="is-4 title"
        >
          €
          <span>
            {{
              donateForm.get('otherAmount')?.value
                ? donateForm.get('otherAmount')?.value
                : donateForm.get('amount')?.value
            }}
          </span>
          via
          {{
            donateForm.get('paymentMethod')?.value === 'Direct debit'
              ? 'Machtiging'
              : donateForm.get('paymentMethod')?.value
          }}
        </h3>

        <div class="field is-grouped is-grouped-centered">
          <div class="control">
            <button
              type="submit"
              [disabled]="donateForm.invalid || isLoading"
              class="button is-link"
            >
              Betaling starten
            </button>
          </div>
          <div class="control">
            <a
              class="button is-link is-light"
              [routerLink]="['actions', action.slug]"
              >Annuleren</a
            >
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
